import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceFormEditor } from "../../service/instance";
// import ksm from "../../utils/datajson/ksm.json";

interface Iksm {
  data: any;
  formParams: any;
  defaultVal: Array<any>;
  formTypes: any;
  isLoadingKsmForm: boolean;
  isLoadingKsmEntry: boolean;
  paramIdWithOpt: any;
  dictValueId: any;
  dictParamsUid: any;
}

const initialState: Iksm = {
  data: [],
  formParams: [],
  defaultVal: [],
  formTypes: [],
  isLoadingKsmForm: false,
  isLoadingKsmEntry: false,
  paramIdWithOpt: [],
  dictValueId: {},
  dictParamsUid: {}
};

export const getFormKsm = createAsyncThunk<any, any, { rejectValue: any }>(
  "ksm",
  async ({id, page_access_uid}, { rejectWithValue }) => {
    try {
      const resp = await instanceFormEditor.get(`/form/${id}`, { 
        headers: {
          'page-access-uid': page_access_uid
          }
       });
      return resp.data.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

// Form Entry
export const getFormEntryList = createAsyncThunk<any, any, { rejectValue: any }>(
  "FormEntry/list-update",
  async ({id, page_access_uid}, { rejectWithValue }) => {
    try {
    const resp: any = await instanceFormEditor.get(`/entry/${id}`, { 
      headers: {
        'page-access-uid': page_access_uid
        }
     });
      return resp.data.data;
    } catch (err) {
      throw rejectWithValue(err);
    }
});

export const ksmSlice = createSlice({
  name: "ksm",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getFormKsm.pending, (state) => {
        state.isLoadingKsmForm = true;
      })
      .addCase(getFormKsm.fulfilled, (state, action) => {
        state.isLoadingKsmForm = false;
        state.formParams = action.payload.form_params;
        state.formTypes = action.payload.form_type;
        let tempVal: any = {};
        let withOpt: any = [];
        let dict: any = {};
        try {
          action.payload.form_params.sort((a:any, b:any) => a.form_param_order_position - b.form_param_order_position)
        } catch(e) { }
        action.payload.form_params.forEach((e: any) => {
          tempVal[e.form_param_id] = e.form_param_default_value == null ? "" : e.form_param_default_value;
          dict[e.form_param_id] = e.form_param_uid
          if (e.form_param_option_values !== null)
            withOpt.push(e.form_param_id);
        });
        state.defaultVal = tempVal;
        state.paramIdWithOpt = withOpt;
        state.dictParamsUid = dict
      })
      .addCase(getFormKsm.rejected, (state) => {
        state.isLoadingKsmForm = false;
      })
      .addCase(getFormEntryList.pending, (state) => {
        state.isLoadingKsmEntry = true;
      })
      .addCase(getFormEntryList.fulfilled, (state, action) => {
        state.isLoadingKsmEntry = false;
        state.formParams = action.payload.form_params;
        state.formTypes = action.payload.form_type;
        let tempVal: any = {};
        let withOpt: any = [];
        let dict: any = {};
        try {
          action.payload.form_params.sort((a:any, b:any) => a.form_param_order_position - b.form_param_order_position)
        } catch(e) { }
        action.payload.form_params.forEach((e: any) => {
          if (e.form_param_values !== null) {
            if (e.form_param_field_type === "03") {
              tempVal[e.form_param_id] = e.form_param_values.map((e: any) => ({
                value: `${e.option_value_id}-${e.label}`,
                label: e.label,
              }));
            } else if (e.form_param_field_type === "09") {
              //(`${e.option_value_id}-${e.label}`)
              tempVal[e.form_param_id] = e.form_param_values.map(
                (e: any) => "1264-Multiplecheckbox 3"
              );
              // "1265-Multiplecheckbox 4",
              // "1262-Multiplecheckbox 1"]))
              // tempVal[e.form_param_uid] = ["1264-Multiplecheckbox 3", "1265-Multiplecheckbox 4"]
            } else if (e.form_param_field_type === "04") {
              tempVal[e.form_param_id] = e.form_param_values.map(
                (e: any) => e.label === 'true' ? true : false
              )[0];
            }
             else if (
              e.form_param_field_type === "00" ||
              e.form_param_field_type === "01" ||
              e.form_param_field_type === "05"
            ) {
              tempVal[`${e.form_param_id}`] = e.form_param_values.map(
                (e: any) => e.label
              )[0];
            } else {
              tempVal[`${e.form_param_id}`] = e.form_param_values.map(
                (e: any) => `${e.option_value_id}~${e.label}`
              )[0];
            }
            let obj: any;
            e.form_param_values.forEach((e: any) => (obj = e.uid));
            state.dictValueId[`${e.form_param_id}`] = obj;
          } else {
            if (e.form_param_field_type === "03") {
              tempVal[e.form_param_id] = []
            }
          }
          if (e.form_param_option_values !== null) {
            withOpt.push(e.form_param_id);
          }
          dict[e.form_param_id] = e.form_param_uid
        });
        state.defaultVal = tempVal;
        state.paramIdWithOpt = withOpt;
        state.dictParamsUid = dict
        //   .map((e: any) => ({
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     [e.form_param_id] : e.form_param_default_value
        // //   }))
        // console.log(" here from tempval Edit ====> ", tempVal);
        // console.log(" here from withOpt Edit ====> ", withOpt);
      })
      .addCase(getFormEntryList.rejected, (state) => {
        state.isLoadingKsmEntry = false;
      });
  },
});

export const {} = ksmSlice.actions;

export default ksmSlice.reducer;
