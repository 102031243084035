
import IRoutes from "../interfaces/IRoutes";
import EmptyPage from "../pages/empty/EmptyPage";
import KeysList from "../pages/keysList/KeysList";
import KeysListDetail from "../pages/keysList/KeysListDetail";
import NotFound from "../pages/notFound/NotFound";

const KeysListRoutes: Array<IRoutes> = [
  {
    element: <KeysList />,
    path: "/keys-list",
  },
  {
    element: <KeysListDetail />,
    path: "/keys-list/detail/:id",
  },
  {
    element: <KeysListDetail />,
    path: "/keys-list/detail/",
  },
];

const KeysListRoutesErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/keys-list",
  },
  {
    element: <NotFound />,
    path: "/keys-list/detail/:id",
  },
  {
    element: <NotFound />,
    path: "/keys-list/detail/",
  },
];

const KeysListRoutesEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/keys-list",
  },
  {
    element: <EmptyPage />,
    path: "/keys-list/detail/:id",
  },
  {
    element: <EmptyPage />,
    path: "/keys-list/detail/",
  },
];

export { KeysListRoutes, KeysListRoutesErr, KeysListRoutesEmpty };
