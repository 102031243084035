import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceFormEditor } from "../../service/instance";
import Swal from "sweetalert2";

export interface IForm {
  isLoadingForm: boolean;
  isLoadingDef: boolean;
  definitionButton: any;
}

const initialState: IForm = {
  isLoadingForm: false,
  isLoadingDef: false,
  definitionButton: [],
};

export const getDefitionForButton = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>(
  "form/definition-button",
  async ({ id, page_access_uid }, { rejectWithValue }) => {
    try {
      const resp = await instanceFormEditor.get(
        `/service/definition/formtype/${id}`,
        {
          headers: {
            'page-access-uid': page_access_uid,
          },
        }
      );
      // console.log('id => ', id, 'params => ', params);
      // console.log("body table =========> ", resp.data.data);
      return resp.data.data;
    } catch (err) {
      // console.log('error disini ====> ', err)
      throw rejectWithValue(err);
    }
  }
);

export const createServiceDefinitionById = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("service/create-service-definition-id", async (data, { rejectWithValue }) => {
  const { entryId, serDevId } = data;
  try {
    console.log(" from service def ===> ", data);
    // console.log('data from getHeader ====> ', data)
    const resp: any = await instanceFormEditor.post(
      `/service/definition/${serDevId}`,
      { entry_id: entryId }
    );
    // console.log('create form value =========> ', resp.data.data)
    // return resp.data.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const serviceDefinition = createSlice({
  name: "serviceDefinition",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder

      // REGION: GET DEFINITION BUTTON
      .addCase(getDefitionForButton.pending, (state) => {
        state.isLoadingForm = true;
      })
      .addCase(getDefitionForButton.fulfilled, (state, action) => {
        state.isLoadingForm = false;
        state.definitionButton = action.payload;
      })
      .addCase(getDefitionForButton.rejected, (state) => {
        state.isLoadingForm = false;
      })

      // REGION: CREATE SERVICE DEFINITION
      .addCase(createServiceDefinitionById.pending, (state) => {
        state.isLoadingDef = true;
      })
      .addCase(createServiceDefinitionById.fulfilled, (state, action) => {
        state.isLoadingDef = false;
        if (action.payload.data.csr_byte != undefined) {
          const base64String = action.payload.data.csr_byte;
          const fileName = action.payload.data.csr_name;
          const downloadLink = document.createElement("a");
          downloadLink.href = `data:application/octet-stream;base64,${base64String}`;
          downloadLink.download = fileName;
          downloadLink.click();
        }
        const successMsg =
          action.payload.msg != ""
            ? action.payload.msg
            : "Success process data!";
        // Swal.fire('Success!',successMsg,'success')
        Swal.fire({
          icon: "success",
          title: "Success!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#98a3a9",
          text: successMsg,
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      })
      .addCase(createServiceDefinitionById.rejected, (state, action) => {
        state.isLoadingDef = false;
        let errMsg = "";
        try {
          errMsg = action.payload.response.data.errors[0].detail;
        } catch (e) {
          errMsg = "Can not process data, Please try again";
        }
        // Swal.fire('Error!', errMsg, 'error')
        Swal.fire({
          icon: "error",
          title: "Error!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#98a3a9",
          text: errMsg,
          didOpen: () => Swal.getConfirmButton()?.focus(),
        });
      });
  },
});

export const {} = serviceDefinition.actions;
export default serviceDefinition.reducer;
