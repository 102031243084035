import {
  Box,
  // TablePagination,
  Pagination,
  Typography,
  // OutlinedInput,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector, RootState} from "../../../store";
import InputForm from "../forms/inputForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import SelectForm from "../forms/selectFormm";
import { useLocation } from "react-router-dom";
import { getHsm } from "../../../store/feature/hsmConfigure";
import { getAllKeys } from "../../../store/feature/keyList";

const PaginationControl = ({ params, setParams, total }: any) => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  // const [pagination, setPagination] = useState({
  //   count: 0,
  //   from: 0,
  //   to: page,
  // });
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { keyPage } = useAppSelector((store: RootState) => store.keyList);
  const { pathname } = useLocation();

  const formHsm = pathname === "/hsm-configuration" ? true : false;
  const formKeys = pathname === "/keys-list" ? true : false;
  
  const schema = yup.object({
    goTo: yup.number().required(),
  });
  const {
    control,
    formState: { errors },
    watch
  } = useForm<{
    goTo: number;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      goTo: 0,
    },
  });

  useEffect(()=> {
    setPage(1)
  }, [keyPage])

  // const handleChangePage = (
  //   event: React.MouseEvent<HTMLButtonElement> | null,
  //   newPage: number
  // ) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const handlePerPage = (e: any) => {
    setPagesize(e.value);
  };

  useEffect(() => {
    let tempPage = page;
    const totalPage = calculateTotalPage();
    if (totalPage < page) {
      tempPage = totalPage;
    }
    setParams({
      ...params,
      page: tempPage,
      pagesize,
    })
  }, [page, pagesize]);

  const handlePaginate = (page: any) => {
    setPage(page);
  };

  const calculateTotalPage = () => {
    return Math.ceil(total / pagesize);
  };

  // const totalRecord = () => {
  //   let total = 0;
  //   if (formHsm) {
  //     total = totalHsmConfig
  //   } else if(formKeys)  {
  //     total = totalKeys
  //   }
  //   return total;
  // };

  const handlePageGoto = (e: any) => {
    if (e === "" || e === undefined) {
      setPage(1);
      return;
    }
    setPage(e);
  };
  const optSelect = [
    {
      label: "10/page",
      value: 10,
    },
    {
      label: "20/page",
      value: 20,
    },
    {
      label: "30/page",
      value: 30,
    },
    {
      label: "50/page",
      value: 50,
    },
    {
      label: "100/page",
      value: 100,
    },
  ];

  return (
    <Box
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingRight: "20px",
        gap: 4,
      }}
    >
      <Box
        sx={{
          // mt: '9px',
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "170px",
        }}
      >
        <Typography
          variant="paragraph1"
          fontFamily='Open Sans'
          paddingBottom={"15px"}
          mt="25px"
        >
          Total {total}
        </Typography>
        <SelectForm
          name="perPage"
          defaultValue={1}
          options={optSelect}
          placeholder="10/page"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          onChangeForm={handlePerPage}
        />
      </Box>

      <Pagination
        count={calculateTotalPage()}
        variant="outlined"
        shape="rounded"
        onChange={(event, page) => handlePaginate(page)}
        sx={{mt: 2}}
        page={page}
      />
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "center",
          width: "100px",
          marginTop: "10px",
        }}
      >
        <Typography variant="body1" fontFamily='Open Sans' paddingBottom={"15px"} mt="25px">
          Go to
        </Typography>
        <InputForm
          onChange={handlePageGoto}
          name="goTo"
          required
          maxLength={3}
          control={control}
          disabled={false}
          errors={errors}
          isLoading={false}
          sxFL={{ color: "red", backgroundColor: "red" }}
          endAdornment={<Typography variant="body16Regular"></Typography>}
          sxEndAdornment={{ backgroundColor: "red" }}
        />
      </Box>
    </Box>
  );
};

export default PaginationControl;
