import axios from "axios";
import { clearStorage, getToken } from "../utils/storage";
import { removeToken } from "../store/feature/user";
import Swal from "sweetalert2";

export const setHeaderRequest = (res: any) => {
  const token = getToken();
  if (token) {
    res.headers["Authorization"] = `Bearer ${token}`;
  }
  return res;
};

export const setError = (err: any) => {
  if (err.response) {
    if (err.response.status === 401 && err?.response.data.message === 'Invalid or expired token' ) {
      removeToken();
      clearStorage();
      window.location.href = "/login";
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err?.response.data.message,
      })
    }
  }
  return Promise.reject(err);
};

export const setHeaderResponse = (res: any) => {
  return res;
};

export const instanceAdmin = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_ADMIN,
  timeout: 5000
})

instanceAdmin.interceptors.request.use(setHeaderRequest, setError);
instanceAdmin.interceptors.response.use(setHeaderResponse, setError);

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API,
  timeout: 5000
});

instance.interceptors.request.use(setHeaderRequest, setError);
instance.interceptors.response.use(setHeaderResponse, setError);

export const instanceFormEditor = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_FORM_EDITOR,
  timeout: 5000
});      

instanceFormEditor.interceptors.request.use(setHeaderRequest, setError);
instanceFormEditor.interceptors.response.use(setHeaderResponse, setError);

export const instanceEndUser = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_END_USER,
  timeout: 5000
});

instanceEndUser.interceptors.request.use(setHeaderRequest, setError);
instanceEndUser.interceptors.response.use(setHeaderResponse, setError);