import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import KeysListSearch from "../../components/molecules/keysList/KeysListSearch";
import KeysListTable from "../../components/molecules/keysList/KeysListTable";
import { getAllKeys, removeEventFlag } from "../../store/feature/keyList";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const KeysList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { dataKeyList, isLoadingKeyList, isLoadingEvent, eventFlag } = useAppSelector((state: RootState)=> state.keyList)
  
  // REGION: DISPATCH ALL KEYS
  useEffect(()=> {
    dispatch(getAllKeys(params))
  },[params])
  

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      dispatch(getAllKeys(params))
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: RETURN TSX
  return (
    <ListDataSkeleton isLoading={isLoadingKeyList} isLoadingEvent={isLoadingEvent} colCount={5} titleWidth={'200px'}>
    <Box>
      <Typography variant="h3" fontFamily='Open Sans' sx={{marginY: '20px'}}>
        Keys List
      </Typography>
      <KeysListSearch
        params={params}
        setParams={setParams}
      />
      <KeysListTable
        data={dataKeyList}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
    </Box>
    </ListDataSkeleton>
  );

};

export default KeysList;