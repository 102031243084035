import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";
import { setPageKey } from "../../../store/feature/keyList";
import Swal from "sweetalert2";
import { useTheme } from '@mui/material/styles';

const schema = yup.object({
  title: yup.string().required(),
});

const KeysListSearch = ({ params, setParams }: any) => {
  const {palette} = useTheme()
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [keysName, setKeysName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [confName, setConfName] = useState("");
  const [createdDateStart, setCreatedDateStart] = useState("");
  const [createdDateEnd, setCreatedDateEnd] = useState("");
  const [userActive, setUserActive] = useState("");
  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    formState: { errors },
  } = useForm<{
    keysName: string;
    groupName: string;
    confName: string;
    createdDateStart: string;
    createdDateEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      keysName: "",
      groupName: "",
      confName: "",
      createdDateStart: "",
      createdDateEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Revoked",
      value: "revoked",
    },
  ];

  const handleKeysName = (e: string) => { setKeysName(e); };
  const handleGroupName = (e: any) => { setGroupName(e); };
  const handleConfName = (e: any) => { setConfName(e); };
  const handleStatusSelect = (e: any) => {
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
    };
  const handleCreatedDateStart = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
        if(dayjs(e).isAfter(dayjs(createdDateEnd))) {
          setAllCreatedDate(NewDate);
        } else {
          setCreatedDateStart(NewDate);
        }
    } else {
        NewDate = ''
        setAllCreatedDate(NewDate);
    }
  };

  const handleCreatedEnd = (e: any) => {
    let NewDate: any
    if (e !== null) {
        NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
        NewDate = ''
    }
    if(conditionCreatedCheck()) setCreatedDateEnd(NewDate);
  }

  const setAllCreatedDate = (date: string) => {
    setCreatedReset(date);
    setCreatedDateStart(date);
    setCreatedDateEnd(date);
  }
  const conditionCreatedCheck = () => {
    if(createdDateStart === "") {
      Swal.fire({
        icon: "warning",
        title: "Warning",
        showCancelButton: false,
        confirmButtonText: "OK",
        confirmButtonColor: palette.primary.main,
        text: "Please select Created Start"
      });
      setCreatedReset("");
      setCreatedDateEnd("");
      return false
    } else {
      return true
    }
  }
  const setCreatedReset = (date: string) => {
    reset({
      keysName: keysName,
      groupName: groupName,
      confName: confName,
      createdDateStart: date,
      createdDateEnd: date,
    });
  }

  useDebouncedEffect(() => setSearchFilterDebounced(keysName), [keysName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(groupName), [groupName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(confName), [confName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(createdDateStart), [createdDateStart], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(createdDateEnd), [createdDateEnd], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(userActive), [userActive], 500);

  useEffect(() => {
    setParams({
      ...params,
      keyName: keysName,
      groupName: groupName,
      confName: confName,
      status: userActive,
      keyCreatedFrom: createdDateStart,
      keyCreatedTo: createdDateEnd,
    });
    dispatch(setPageKey(keysName || groupName || confName || userActive || createdDateStart || createdDateEnd ))
  }, [searchFilterDebounced, createdDateEnd]);

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1, mt: "-1rem", mb: "0.7rem" }}>
        <InputForm
          name="keysName"
          errors={errors}
          control={control}
          required
          placeholder="Keys Name"
          maxLength={100}
          isLoading={false}
          onChange={handleKeysName}
        />
        <InputForm
          name="groupName"
          errors={errors}
          control={control}
          required
          placeholder="Group Name"
          maxLength={100}
          isLoading={false}
          onChange={handleGroupName}
        />
        <InputForm
          name="confName"
          errors={errors}
          control={control}
          required
          placeholder="Configuration Name"
          maxLength={100}
          isLoading={false}
          onChange={handleConfName}
        />
        <DatePickerForm
          name="createdDate"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Keys Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedDateStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Keys Created End"
          disabled={false}
          isLoading={false}
          required
          minDate={(new Date(createdDateStart).getTime())}
          onChange={handleCreatedEnd}
        />
        <SelectForm
          name="userActive"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
      </Box>
    </Box>
  );
};

export default KeysListSearch;
