import React from 'react';
import {
  InputForm,
  CheckboxFormMultiple,
  SelectFormm,
  TextAreaForm,
  RadioForm,
  CheckboxForm,
} from '../index';
import { Button, Typography, CssBaseline } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


interface Iprops {
  sxFC?: any;
  sxFL?: any;
  name: string;
  label: string;
  labelValue: string;
  errors?: any;
  control?: any;
  defaultValue: any;
  disabled?: boolean;
  required?: boolean;
  onChange?: Function;
  isLoading?: boolean;
  type: string;
  options?: Array<any>;
  setTypeGrid?: Function;
  handleChange?: Function;
  rows: any;
  columns: any;
  handleAddTable: Function;
  parentId: string;
  watch: Function;
  helpNotation: string;
  keyVal: any;
  isCreate: boolean;
}

// persebar judul label dan font warna hitam
// text help notation kecil dan abu"
// placeholder ambil dari default value jika ada atau jadi value
//

export default function DynamicForm(props: Iprops) {
  const {
    name,
    label,
    control,
    defaultValue,
    errors,
    setTypeGrid,
    handleChange,
    rows,
    columns,
    handleAddTable,
    helpNotation,
    type = '',
    options = [],
    parentId,
    watch,
    keyVal,
    isCreate,
    required
  } = props;

  // Uneditable field = 00
  // Input textfield = 01
  // Dropdown = 02
  // Multiple choice = 03
  // Checkbox = 04
  // Textbox = 05
  // Separator = 06
  // Button = 07 // tidak terpakai
  // textfield = 08, input grid buat skip value untuk di post
  // Multiple checkbox=09
  // Multiple radiobutton=10
  // Grid=11 // berbentuk table dgn tombol delete, dan tombol add d luar table

  const schema = yup.object({
    name: yup.string().required(),
  });

  const renderComponent = () => {
    switch (type) {
      case '00':
        // console.log('keyVal =====> ', typeof keyVal, keyVal)
        return (
          <>
            <InputForm
              name={name}
              label={label}
              errors={errors}
              control={control}
              required={required}
              placeholder={`Type Your ${label}`}
              maxLength={100}
              isLoading={false}
              disabled
              sxFL={{color: 'black'}}
              key={keyVal}
            />
            <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      case '01':
        // console.log('keyVal =====> 01 ', typeof keyVal, keyVal)
        const isFormDisable = !isCreate ? [52,42,22,41].includes(keyVal) : false
        const isWatchDisable =  isFormDisable ? true : !watch(parentId)
        return (
          <>
          <InputForm
            name={name}
            label={label}
            errors={errors}
            control={control}
            disabled={
              Number(parentId) !== 0 ? isWatchDisable : false || isFormDisable
            }
            required={required}
            placeholder={`Type Your ${label}`}
            maxLength={100}
            isLoading={false}
            sxFL={{color: 'red'}}
            key={keyVal}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      case '02':
        const selectOpt = options.map((item) => {
          return {
            value: `${item.value}~${item.label}`,
            label: item.label,
            parent_val: item.parent_val,
          };
        });
        return (
          <>
          <SelectFormm
            name={name}
            label={label}
            defaultValue={1}
            options={selectOpt}
            placeholder="Please Select"
            isLoading={false}
            control={control}
            errors={errors}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            key={keyVal}
            required={required}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      case '03':
        // console.log("03 option value ==> ", options);
        const selectOptMulti = options.map((item) => {
          return {
            value: `${item.value}~${item.label}`,
            label: item.label,
            parent_val: item.parent_val,
          };
        });
        return (
          <>
          <SelectFormm
            name={name}
            label={label}
            defaultValue={1}
            options={selectOptMulti}
            placeholder="Please Select"
            isLoading={false}
            control={control}
            errors={errors}
            key={keyVal}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            isMulti
            required={required}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      case '04':
        const defaultOpt = {
          value: label,
          label: label,
        };
        return (
          <>
          <CheckboxForm
            name={name}
            // label={label}
            options={defaultOpt}
            errors={errors}
            control={control}
            required={required}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            key={keyVal}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation + ' haha '}</Typography>
          </>
        );

      case '05':
        return (
          <>
          <TextAreaForm
            name={name}
            label={label}
            // disabled={parentId ? !watch(parentId) : false}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            errors={errors}
            control={control}
            required={required}
            placeholder="Type Title"
            maxLength={100}
            isLoading={false}
            key={keyVal}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );

      case '06':
        return (
          <>
            <Typography variant="h4" sx={{ fontFamily: 'Inter' }}>
              {label}
            </Typography>
            <CssBaseline />
            <hr />
          </>
        );

      case '07':
        return <Button>{label}</Button>;

      case '08': {
        // setTypeGrid?.((prev: Array<string>) => {
        //   const temp = [...prev];
        //   temp.push();
        //   return temp;
        // });
        return (
          <>
          <InputForm
            name={name}
            label={label}
            errors={errors}
            control={control}
            required={required}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            placeholder={`Type Your ${label}`}
            maxLength={100}
            isLoading={false}
            key={keyVal}
            onChange={(val: any) =>
              handleChange?.({
                id: name,
                value: val,
                title: label,
              })
            }
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      }
      case '09':
        // console.log('isi option from 09 ===> ', options.map((e: any)=> {value: e.value, label : e.label}));
        const checkMulOpt = options.map((item) => {
          return {
            value: `${item.value}~${item.label}`,
            label: item.label,
            parent_val: item.parent_val,
          };
        });
        return (
          <>
          <CheckboxFormMultiple
            name={name}
            label={label}
            options={checkMulOpt}
            errors={errors}
            control={control}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
            required={required}
            sxFL={{ width: '20rem' }}
            key={keyVal}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );

      case '10':
        const radioOpt = options.map((item) => {
          return {
            value: `${item.value}~${item.label}`,
            label: item.label,
            parent_val: item.parent_val,
          };
        });
        return (
          <>
          <RadioForm
            name={name}
            label={label}
            options={radioOpt}
            defaultValue={1}
            errors={errors}
            control={control}
            required={required}
            key={keyVal}
            disabled={Number(parentId) !== 0 ? !watch(parentId) : false}
          />
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );
      case '11':
        return (
          <>
          <div
            style={{
              height: 200,
              width: '70%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
            }}
          >
            <DataGrid rows={rows} columns={columns} hideFooter />
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: '20px',
              }}
            >
              <Button variant="contained" onClick={() => handleAddTable()}>
                Add Value
              </Button>
            </div>
          </div>
          <Typography variant="body2" color='#cfcfcf' mb={2}>{helpNotation}</Typography>
          </>
        );

      default:
        break;
    }
  };
  return <>{renderComponent()}</>;
}
