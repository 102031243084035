import HsmConfiguration from "../pages/hsmConfiguration/HsmConfiguration";
import HsmConfigurationField from "../pages/hsmConfiguration/HsmConfigurationField";
import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";
import EmptyPage from "../pages/empty/EmptyPage";

const HsmConfigurationRoutes: Array<IRoutes> = [
  {
    element: <HsmConfiguration />,
    path: "/hsm-configuration",
  },
  {
    element: <HsmConfigurationField />,
    path: "/hsm-configuration/create",
  },
  {
    element: <HsmConfigurationField />,
    path: "/hsm-configuration/edit/:id",
  }
];

const HsmConfigurationRoutesErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/hsm-configuration",
  },
  {
    element: <NotFound />,
    path: "/hsm-configuration/create",
  },
  {
    element: <NotFound />,
    path: "/hsm-configuration/edit/:id",
  }
];

const HsmConfigurationRoutesEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/hsm-configuration",
  },
  {
    element: <EmptyPage />,
    path: "/hsm-configuration/create",
  },
  {
    element: <EmptyPage />,
    path: "/hsm-configuration/edit/:id",
  }
];

export { HsmConfigurationRoutes, HsmConfigurationRoutesErr, HsmConfigurationRoutesEmpty };
