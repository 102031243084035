import { Box, Stack, Button, Typography } from '@mui/material';
import { useAppSelector, useAppDispatch, RootState, } from '../../../store/index';
import { useEffect, useState } from 'react';
import { getFormKsm, getFormEntryList } from '../../../store/feature/ksm';
import { createFormValue, createFormValueMulti, removeEventFlag, updateFormValueEntry, } from '../../../store/feature/form';
import DynamicForm from '../../molecules/forms/dynamicForm/DynamicForm';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useLocation, useNavigate, useSearchParams  } from 'react-router-dom';
import DetailDataSkeleton from '../../template/skeleton/DetailDataSkeleton';
import { dynamicYup } from "../../../utils/validation/dynamicValidator";
import { initialAccess } from '../../../store/feature/user';

const DynamicField = () => {

  //#region INIT

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const [valueGrid, setValueGrid] = useState<any>({});
  const [headerGrid, setHeaderGrid] = useState<any>({});
  const [dataGrid, setDataGrid] = useState<Array<object>>([]);
  const [typeGrid, setTypeGrid] = useState<Array<string>>([]);
  const [optValSend, setOptValSend] = useState<Array<string>>([]);
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [formValueMulti, setFormValueMulti] = useState<any>({})
  const idVal = searchParams.get('form-type-uid');
  const isCreate = pathname.includes('create');
  const tablePath = isCreate ? pathname.split('?')[0].replace('/create','') : pathname.split('?')[0].replace('/edit','')
  const tablePage = `${tablePath}?form-type-uid=${idVal}`
  const idEntryPath = searchParams.get('params-uid')
  const pathTitle = tablePath.replace("/","").replace("-"," ")
  const formTitle = `${ isCreate ? 'Create' : 'Update'} ${pathTitle}`
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const {
    formParams,
    formTypes,
    defaultVal,
    paramIdWithOpt,
    dictValueId,
    dictParamsUid,
    isLoadingKsmForm,
    isLoadingKsmEntry,
  } = useAppSelector((state: RootState) => state.ksm);
  const {
    entryId,
    eventFlag,
    isLoadingEvent
  } = useAppSelector((state: RootState) => state.form);

  const schema = yup.object(
    dynamicYup(formParams)
  );

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { ...defaultVal },
  });

  //#endregion


  //#region DISPATCH

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === idVal)
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  useEffect(() => {
    if(
      access.page_access_uid != "" && 
      access.page_access_uid != undefined && 
      access.page_access_uid != null
    ) {
      if (isCreate) {
        dispatch(getFormKsm({
          id: idVal,
          page_access_uid: access.page_access_uid
        }));
      } else {
        dispatch(getFormEntryList({
          id: idEntryPath,
          page_access_uid: access.page_access_uid
        }));
      }
    }
  }, [access]);
  
  useEffect(() => {
    reset(defaultVal);
  }, [JSON.stringify(defaultVal)]);

  useEffect(() => {
    if(eventFlag) {
      navigate(tablePage)
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);

  useEffect(() => {
    let entryUid: any;
    if (isCreate) {
      entryUid = entryId;
    } else {
      entryUid = idEntryPath;
    }
    let optVal: any = {
      form_entry_uid: entryUid,
      form_param_values: optValSend.map((item: any) => {
        const form_param_uid = item.form_param_values
        console.log('form param id ===> ', form_param_uid)
        if (item.form_values[0].constructor === Array) {
          // return 1;
          const form_value = item.form_values[0].map((item: any) => {
            let form_option_value_id: any;
            let form_value: any;
            if (typeof item !== 'object') {
              form_option_value_id = parseInt(item.split('~')[0], 10);
              form_value = item.split('~')[1];
            } else {
              form_option_value_id = parseInt(item.value.split('~')[0], 10);
              form_value = item.value.split('~')[1];
            }
            return {
              form_option_value_id,
              form_value,
            };
          });
          return {
            form_param_uid,
            form_param_values_and_option: form_value,
          };
        } else {
          // return 0;
          const form_value = item.form_values[0].split('~')[1];
          const form_option_value_id = parseInt(
            item.form_values[0].split('~')[0],
            10
          );
          return {
            form_param_uid,
            form_param_values_and_option: [
              {
                form_option_value_id,
                form_value,
              },
            ],
          };
        }
      }),
    };
    setFormValueMulti(optVal)
    if (entryId !== 0) {
      dispatch(createFormValueMulti({
        optVal,
        isCreate,
        page_access_uid: access.page_access_uid
      }));
    }
  }, [entryId]);

  const handleSave = () => {
    let temp = watch();
    const optVal: any = [];
    const inputVal: any = [];
    for (const key in temp) {
      if (paramIdWithOpt.includes(Number(key)) && temp[key] !== '') { // CASE 1

        // SEND TO API MULTIPLE
        if (isCreate) {
          let newVal = {
            form_param_values: dictParamsUid[key],
            form_values: [temp[key]],
          };
          optVal.push(newVal);
        }

        // SEND TO API CREATE/UPDATE
        if (!isCreate) {
          let newInputVal: any;
          if(
            temp[key]?.toString() != "null~" &&
            typeof temp[key] == "string"
          ) {
            const idVal = temp[key].split("~")
            newInputVal = {
              form_val_option_value_id: Number(idVal[0]),
              form_val_uid: dictValueId[key],
              form_val_value: idVal[1]
            };
          } else if (temp[key]?.toString() != "null~") {
            if(temp[key] != null) {
              let newVal = {
                form_param_values: dictParamsUid[key],
                form_values: [temp[key]],
              };
              optVal.push(newVal);
            } else {
              let newVal = {
                form_param_values: dictParamsUid[key],
                form_values: [[]],
              };
              optVal.push(newVal);
            }
          } else {
            newInputVal = {
              form_val_option_value_id: 0,
              form_val_uid: dictValueId[key],
              form_val_value: "",
            };
          }
          if(newInputVal != null) {
            inputVal.push(newInputVal);
          }
        }

      } else if (paramIdWithOpt.includes(Number(key)) && temp[key] === '') {  // CASE 2

        // SEND TO API CREATE/UPDATE
        let newInputVal: any;
        if (isCreate) {
          newInputVal = {
            form_param_uid: dictParamsUid[key],
            form_values: [''],
          };
        } else {
          newInputVal = {
            form_val_uid: dictValueId[key],
            form_val_value: [''],
          };
        }
        inputVal.push(newInputVal);

      } else if (!paramIdWithOpt.includes(Number(key)) && temp[key] === '') {  // CASE 3

        // SEND TO API CREATE/UPDATE
        let newInputVal: any;
        if (isCreate) {
          newInputVal = {
            form_param_uid: dictParamsUid[key],
            form_values: [temp[key]?.toString()],
          };
        } else {
          newInputVal = {
            form_val_uid: dictValueId[key],
            form_val_value: temp[key]?.toString(),
          };
        }
        inputVal.push(newInputVal);

      } else if (!paramIdWithOpt.includes(Number(key)) && temp[key] !== '') {  // CASE 4

        // SEND TO API CREATE/UPDATE
        let newInputVal: any;
        if (isCreate) {
          newInputVal = {
            form_param_uid: dictParamsUid[key],
            form_values: [temp[key]?.toString()],
          };
        } else {
          newInputVal = {
            form_val_uid: dictValueId[key],
            form_val_value: temp[key]?.toString(),
          };
        }
        inputVal.push(newInputVal);

      }
    }
    setOptValSend(optVal);
    if (isCreate) {
      dispatch(
        createFormValue({
          form_param_values: inputVal,
          form_type_uid: idVal,
          page_access_uid: access.page_access_uid
        })
      );
    } else {
      dispatch(
        updateFormValueEntry({
          entryId: idEntryPath,
          params: inputVal,
          page_access_uid: access.page_access_uid
        })
      );
    }
  };

  //#endregion


  //#region HANDLE
  
  const handleGenerateOptions = (e: any) => {
    if (e.form_param_parent_param_id !== 0) {
      return e.form_param_option_values?.filter(
        (item: any) =>
          item.parent_val ===
          Number(watch(`${e.form_param_parent_param_id}`)?.split('~')[0])
      );
    }
    return e.form_param_option_values || [];
  };

  const handleAddTable = () => {
    const temp = [];
    for (const key in headerGrid) {
      if (Object.prototype.hasOwnProperty.call(headerGrid, key)) {
        const element: string = headerGrid[key];
        temp.push({ field: key, headerName: element });
      }
    }
    setColumns(temp);
    setRows((prev: any) => {
      return [...prev, { id: new Date().toString(), ...valueGrid }];
    });
    setValueGrid({});
  };

  const handleChange = (data: any) => {
    const result = { ...valueGrid };
    const resultHeader = { ...headerGrid };
    result[data.id] = data.value;
    resultHeader[data.id] = data.title;
    setValueGrid(result);
    setHeaderGrid(resultHeader);
  };

  //#endregion


  //#region RETURN TSX

  return (
    <DetailDataSkeleton isLoading={isLoadingKsmForm || isLoadingKsmEntry} isLoadingEvent={isLoadingEvent} isTitle={false} inputCount={15} titleWidth={'200px'}>
      <Box
      sx={{ marginBottom: '60px' }}
      component="form"
      onSubmit={handleSubmit(handleSave)}>
      <Typography
      variant="h3"
      fontFamily="Open Sans"
      sx={{ marginBottom: '30px' }}
      >
        {formTitle}
      </Typography>
        {formParams.map((e: any) => (
          <>
            <DynamicForm
              control={control}
              type={e.form_param_field_type}
              name={`${e.form_param_id}`}
              label={e.form_param_title}
              labelValue={e.form_param_values}
              defaultValue={undefined}
              keyVal={e.form_param_id}
              key={e.form_param_id}
              errors={errors}
              setTypeGrid={setTypeGrid}
              options={handleGenerateOptions(e)}
              handleChange={handleChange}
              rows={rows}
              columns={columns}
              handleAddTable={handleAddTable}
              parentId={`${e.form_param_parent_param_id}`}
              watch={watch}
              helpNotation={e.form_param_help_notation}
              isCreate={isCreate}
              required={e.form_param_mandatory}
            />
          </>
        ))}
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate(tablePage)}>
            Cancel
          </Button>

          { isCreate && access.create &&
            <Button
              type="submit"
              variant="contained">
              Submit
            </Button>
          }

          { !isCreate && access.update &&
            <Button
              type="submit"
              variant="contained">
              Update
            </Button>
          }

        </Stack>
      </Box>
    </DetailDataSkeleton>
  );

  //#endregion

};

export default DynamicField;
