import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./feature/user";
import formGroup from "./feature/formGroup";
import hsmConfigure from "./feature/hsmConfigure";
import keyList from "./feature/keyList";
import menu from "./feature/menu";
import ksm from "./feature/ksm";
import form from "./feature/form";
import serviceDefinition from "./feature/serviceDefinition";
// import endUser from "./feature/endUser";

export const store = configureStore({
  reducer: {
    user: userReducer,
    fGroup: formGroup,
    hsmConfig: hsmConfigure,
    keyList: keyList,
    menu: menu,
    ksm: ksm,
    form: form,
    serDef: serviceDefinition,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
