import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { menuListCSS } from "react-select/dist/declarations/src/components/Menu";
import { instance, instanceFormEditor } from "../../service/instance";
import { getPageAccessUid } from "../../utils/accessHelper";


export interface IMenu {
    isLoadingMenu: boolean;
    menuData : any;
    menuDataByUid: any;
}

const initialState: IMenu = {
    isLoadingMenu: false,
    menuData: [],
    menuDataByUid: [],
}

const ACCESS_ID = "/Dashboard"

//get menu data form group, so many menu here
export const getMenuList = createAsyncThunk<any, any, { rejectValue: any }>(
    "admin/list",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), ACCESS_ID)
        const resp = await instance.get("/menu/groups", {
          headers: {
            'page-access-uid': uid
          }
        });
        return resp.data.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );
//get menu data by uid, only one group here
export const getMenuListByUID = createAsyncThunk<any, any, { rejectValue: any }>(
  "admin/listByUID",
  async (data, { rejectWithValue, getState }) => {
    try {
      const uid = await getPageAccessUid(getState(), ACCESS_ID)
      const resp = await instanceFormEditor.get(`/form-type/group?formGroupUid=${data.uid}`, {
        headers: {
          'page-access-uid': uid
        }
      });
      const outputObj = [
        {
          "form_group": {
            "form_group_id": data.uid,
            "form_type_name": resp.data.data.form_group_name
          },
          "form_types": resp.data.data.form_types
        }
      ]
      return outputObj;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);


export const menuSlice = createSlice({
    name: "menu",
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder
        .addCase(getMenuList.pending, (state)=> {
            state.isLoadingMenu = true
        })
        .addCase(getMenuList.fulfilled, (state, action)=> {
            // console.log('from addcase fullfiled menuData slice ===> ', action.payload)
            state.menuData = action.payload
        })
        .addCase(getMenuList.rejected, (state)=> {
            state.isLoadingMenu = false
        })
        .addCase(getMenuListByUID.pending, (state)=> {
          state.isLoadingMenu = true;
        })
        .addCase(getMenuListByUID.fulfilled, (state, action)=> {
          state.menuDataByUid = action.payload
        })
    },
})




export const {} = menuSlice.actions;

export default menuSlice.reducer;