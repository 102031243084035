import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { removeEventFlag, updateHsm, updateHsmPassword } from "../../store/feature/hsmConfigure";
import { LoadingButton } from "@mui/lab";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import Swal from "sweetalert2";
import hsmConfiguration from "../../utils/validation/hsmConfiguration";
import { initialAccess } from "../../store/feature/user";

const HsmConfigurationField = () => {

  // REGION: INIT VARIABLE
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname, state } = location;
  const editFgroup = pathname.includes("edit");
  const { isLoadingUpdateHsm, eventFlag } = useAppSelector((state: RootState)=> state.hsmConfig)
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/hsm-configuration")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  // REGION: INIT FORM
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    hsmConfigName: string;
    hsmPin: string;
    hsmProvider: string;
    configId: string;
    id: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(hsmConfiguration),
    defaultValues: {
      hsmConfigName: "",
      hsmPin: "",
      hsmProvider: "",
      configId: "",
      id: "",
    },
  });
  

// REGION: DISPATCH SUCCESS EVENT
useEffect(() => {
  if(eventFlag) {
    navigate("/hsm-configuration")
    dispatch(removeEventFlag())
  }
}, [eventFlag]);


// REGION: SET FORM
useEffect(() => {
  if (editFgroup) {
    reset({
      hsmConfigName: state.hsmConfigName,
      hsmPin: state.hsmPin,
      hsmProvider: state.eccProvider,
      configId: state.configId,
      id: state.id
    });
  } else {
    reset();
  }
}, [ ]);


// REGION: DISPATCH UPDATE DATA
const onSubmit = (e: any) => {
  Swal.fire({
    title: "Update Confirmation",
    text: "Are you sure you want to update this HSM Configuration data?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#F4BB44",
    cancelButtonColor: "#808080",
    confirmButtonText: "Yes",
    cancelButtonText: "No",
    reverseButtons: true
  }).then((result) => {
    if (result.isConfirmed) {
      const updateData = {
        hsm_name: e.hsmConfigName,
        ecc_provider: e.hsmProvider
      };
      const updatePassword = {
        hsm_pin: e.hsmPin,
      };
      if (editFgroup) {
        dispatch(updateHsm(updateData));
        if(state.hsmPin != e.hsmPin) {
          dispatch(updateHsmPassword(updatePassword))
        }
      }
    }
  });
};

// REGION: RETURN TSX
return (
  <DetailDataSkeleton isLoading={state==undefined} isLoadingEvent={isLoadingUpdateHsm} inputCount={4} titleWidth={'200px'}>
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {editFgroup ? "HSM Update" : "Create Form Group"}
      </Typography>
      <InputForm
        name="hsmConfigName"
        label="HSM Config Name"
        errors={errors}
        control={control}
        required
        placeholder="Type Config Name..."
        maxLength={100}
        isLoading={false} />
      <InputForm
        name="hsmPin"
        label="HSM PIN"
        errors={errors}
        control={control}
        required
        placeholder="Type PIN..."
        maxLength={100}
        isLoading={false} />
      <InputForm
        name="hsmProvider"
        label="HSM Provider"
        errors={errors}
        control={control}
        required
        placeholder="Type Provider..."
        maxLength={100}
        isLoading={false} />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end">
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/hsm-configuration")}>
          Cancel
        </Button>
        { access.update && <Button
            variant="contained"
            type="submit">
            Update
          </Button>
        }
      </Stack>
      </Box>
    </DetailDataSkeleton>
  );
};

export default HsmConfigurationField;
