import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import { store } from '../index';
import Swal from "sweetalert2";
import { getPageAccessUid } from "../../utils/accessHelper";

interface IHsmConfiguration {
  isLoadingHsmConfig: boolean;
  isLoadingUpdateHsm: boolean;
  isLoadingHsmPassword: boolean;
  eventFlag: boolean;
  dataHsmConfig: any;
  totalHsmConfig: any;
  msgSccsFGr: string;
}

const initialState: IHsmConfiguration = {
  isLoadingHsmConfig: false,
  isLoadingUpdateHsm: false,
  isLoadingHsmPassword: false,
  eventFlag: false,
  dataHsmConfig: [],
  totalHsmConfig: 0,
  msgSccsFGr: "",
};

  const HSM_CONFIG_ACCESS_ID = "/hsm-configuration"

  export const getHsm = createAsyncThunk<any, any, { rejectValue: any }>(
    "hsm-configure/list",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), HSM_CONFIG_ACCESS_ID)
        const resp: any = await instance.get(`/get_hsm`, {
          params: data,
          headers: {
            'page-access-uid': uid
          }
        });
        //console.log("getHsm: ", resp.data.data)
        const responseData: any[] = []
        responseData.push(resp.data.data)
        let totalRecord = responseData.length;
        return {responseData, totalRecord};
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const updateHsm = createAsyncThunk<any, any, { rejectValue: any }>(
    "hsm-configure/update",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), HSM_CONFIG_ACCESS_ID)
        const resp = await instance.put(
          `/update_hsm`,
          data,
          {
            headers: {
              'page-access-uid': uid
            }
          }
        );
        console.log("updateHsm: ", resp)
        return resp.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const updateHsmPassword = createAsyncThunk<any, any, { rejectValue: any }>(
    "hsm-configure/update-password",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), HSM_CONFIG_ACCESS_ID)
        const resp = await instance.put(
          `/update_hsm_password`,
          data,
          {
            headers: {
              'page-access-uid': uid
            }
          }
        );
        console.log("updateHsmPassword: ", resp)
        return resp.data;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const hsmConfigure = createSlice({
    name: "hsmConfigure",
    initialState,
    reducers: {
      setEventFlag: (state) => {
        state.eventFlag = true;
      },
      removeEventFlag: (state) => {
        state.eventFlag = false;
      }
    },
    extraReducers(builder) {
        builder

        // REGION: GET HSM CONFIG
        .addCase(getHsm.pending, (state) => {
          state.isLoadingHsmConfig = true;
        })
        .addCase(getHsm.fulfilled, (state, action) => {
          state.isLoadingHsmConfig = false;
          const { responseData, totalRecord } = action.payload
          state.dataHsmConfig = responseData
          state.totalHsmConfig = totalRecord
        })
        .addCase(getHsm.rejected, (state) => {
          state.isLoadingHsmConfig = false;
          state.dataHsmConfig = []
          state.totalHsmConfig = 0
        })

        // REGION: UPDATE HSM CONFIG
        .addCase(updateHsm.pending, (state) => {
          state.isLoadingUpdateHsm = true;
          state.eventFlag = false
        })
        .addCase(updateHsm.fulfilled, (state) => {
          state.isLoadingUpdateHsm = false;
          // Swal.fire("Configuration Updated!", "Your configuration has been updated", "success")
          Swal.fire({
            icon: "success",
            title: "Configuration Updated!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: "Your configuration has been updated",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(updateHsm.rejected, (state) => {
          state.isLoadingUpdateHsm = false;
          // Swal.fire("Update Configuration Error!", "Can not update your configuration, Please try again", "error")
          Swal.fire({
            icon: "error",
            title: "Update Configuration Error!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: "Can not update your configuration, Please try again",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(removeEventFlag())
          });
        })

        // REGION: UPDATE HSM PASSWORD
        .addCase(updateHsmPassword.pending, (state) => {
          state.isLoadingHsmPassword = true;
        })
        .addCase(updateHsmPassword.fulfilled, (state) => {
          state.isLoadingHsmPassword = false;
        })
        .addCase(updateHsmPassword.rejected, (state) => {
          state.isLoadingHsmPassword = false;
        })

    }
  });
  
  export const { setEventFlag, removeEventFlag } = hsmConfigure.actions;
  export default hsmConfigure.reducer;
  