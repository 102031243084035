import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import PaginationControl from "../pagination/Pagination";
import { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import "./FormGroupDataGrid.css";
import { initialAccess } from "../../../store/feature/user";

// Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const HsmConfigurationTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalHsmConfig } = useAppSelector(
    (store: RootState) => store.hsmConfig
  );
  const [access, setAccess] = useState(initialAccess);
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const [mask, setMask] = useState(true);

  useEffect(() => {
    const obj = accessCrud.find(
      (item: any) => item.uid === "/hsm-configuration"
    );
    setAccess(obj != undefined ? obj : initialAccess);
  }, [accessCrud]);

  const customCellRender = (params: GridCellParams) => {
    // Button for Edit
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/hsm-configuration/edit/${params.id}`, { state: params.row });
    };
    return (
      <Stack spacing={2} direction="row">
        {access.read ? (
          <Button variant="contained" onClick={onEdit}>
            Update
          </Button>
        ) : (
          <Typography variant="body2"> No Action Available </Typography>
        )}
      </Stack>
    );
  };

  const customCellRenderPin = (params: GridCellParams) => {
    // Button for Edit
    const onChange = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      setMask((val) => (val = !val));
    };
    return (
      <Grid container spacing={2}>
        <Grid item xs={10} >
          <Typography variant="body2" sx={{
            display: 'flex',
            alignItems: 'center', // Center the text vertically
            height: '100%', // Set the height to occupy the full space of the container
          }}>
            {mask ? params.row.hsmPin.replace(/./g, "*") : params.row.hsmPin}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton size="small" onClick={onChange}>
            {mask ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "configId",
      headerName: "Config ID",
      flex: 1,
      minWidth: 320,
      headerClassName: "centered",
    },
    {
      field: "hsmConfigName",
      headerName: "HSM Config Name",
      flex: 1,
      minWidth: 320,
    },
    {
      field: "hsmPin",
      headerName: "HSM PIN",
      flex: 1,
      minWidth: 320,
      renderCell: customCellRenderPin,
    },
    {
      field: "eccProvider",
      headerName: "ECC Provider",
      flex: 1,
      minWidth: 320,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: customCellRender,
    },
  ];

  //getting rows for datagrid
  const rows = data.map((row: any) => ({
    id: row.kms_hsm_conf_id,
    configId: row.kms_hsm_conf_id,
    hsmConfigName: row.kms_hsm_conf_name,
    eccProvider: row.kms_hsm_conf_ecc_provider,
    hsmPin: row.kms_hsm_conf_pin,
  }));

  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl
        params={params}
        setParams={setParams}
        total={totalHsmConfig}
      />
    </>
  );
};

export default HsmConfigurationTable;
