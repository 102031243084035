import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import { InputForm, TextAreaForm, SelectFormm } from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteKey, getKeyById, removeEventFlag, revokeKey } from "../../store/feature/keyList";
import Swal from "sweetalert2";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { initialAccess } from "../../store/feature/user";
import { useTheme } from '@mui/material/styles';

const KeysListDetail = () => {

  // REGION: INIT VARIABLE
  const {palette} = useTheme()
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { pathname } = location;
  const idParam = useParams();
  const {
    dataKeyDetail,
    isLoadingKeyList,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((root: RootState) => root.keyList);
  const {
    key_uid,
    key_id,
    key_name,
    key_group_name,
    key_config_name,
    key_created_at,
    key_expiry_date,
    key_status,
    key_revoke_date,
    key_revoke_reason,
    key_kcv,
    key_csr,
    encryption_usage,
    decryption_usage,
    signing_usage,
  } = dataKeyDetail
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    keyUid: string,
    id: string;
    keyName: string;
    configName: string;
    groupName: string;
    expiryDate: string;
    createdDate: string;
    status: string;
    revokeDate: string;
    revokeReason: string;
    kcv: string;
    csr: string;
    encryptionUsage: string;
    descryotionUsage: string;
    signingUsage: string;
  }>({
    reValidateMode: "onChange",
    //resolver: yupResolver(formGroup),
    defaultValues: {
      keyUid: "",
      id: "",
      keyName: "",
      configName: "",
      groupName: "",
      createdDate: "",
      expiryDate: "",
      status: "",
      revokeDate: "",
      revokeReason: "",
      kcv: "",
      csr: "",
      encryptionUsage: "",
      descryotionUsage: "",
      signingUsage: "",
    },
  });
  const optRevokeSelect = [
    {
      label: "Choose",
      value: "Choose",
    },
    {
      label: "Unspecified",
      value: "unspecified",
    },
    {
      label: "Key Compromise",
      value: "key compromise",
    },
    {
      label: "Superseded",
      value: "superseded",
    },
    {
      label: "Key Pair Already Expired",
      value: "key pair already expired",
    },
  ];
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);

  // REGION: GET CRUD ACCESS
  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/keys-list")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  // REGION: SET FORM
  useEffect(() => {
    reset({
      keyUid: key_uid,
      id: key_id,
      keyName: key_name,
      groupName: key_group_name,
      configName: key_config_name,
      createdDate:key_created_at,
      expiryDate: key_expiry_date,
      status: key_status,
      revokeDate: key_revoke_date ? key_revoke_date : "No revoked date",
      revokeReason: key_revoke_reason ? key_revoke_reason : "Choose",
      kcv: key_kcv,
      csr: key_csr,
      encryptionUsage: encryption_usage,
      descryotionUsage: decryption_usage,
      signingUsage: signing_usage,
    });
  }, [ dataKeyDetail ]);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      navigate("/keys-list")
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: DISPATCH GET KEY DETAIL
  useEffect(() => {
    if (idParam.id !== undefined) {
      dispatch(getKeyById(idParam.id))
    } else {
      dispatch(getKeyById(""))
    }
  }, []);


  // REGION: DISPATCH RE-KEY
  const onRevokeKey = (e: any) => {
      Swal.fire({
        title: "Revoke Confirmation",
        text: "Are you sure you want to revoke this key?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if(e.revokeReason == "Choose") {
            // Swal.fire("Revoke Key Failed", "Please choose revoke reason to continue!", "warning");
            Swal.fire({
              icon: "warning",
              title: "Revoke Key Failed",
              showCancelButton: false,
              confirmButtonText: "OK",
              confirmButtonColor: palette.primary.main,
              text:"Please choose revoke reason to continue!",
              didOpen: () => Swal.getConfirmButton()?.focus()
            })
          } else {
            const revokeData = [{
              key_uid: e.keyUid,
              revoke_reason: e.revokeReason
            }];
            dispatch(revokeKey(revokeData));
          }
        }
      });
  };


  // REGION: DISPATCH DESTROY KEY
  const onDestroyKey = () => {
      Swal.fire({
        title: "Destroy Key Confirmation",
        text: "Are you sure you want to destroy this key?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if(key_revoke_reason == null) {
            // Swal.fire("Key Destroy Failed", "Can not destroy key, You have to revoke key first.", "warning");
            Swal.fire({
              icon: "warning",
              title: "Key Destroy Failed",
              showCancelButton: false,
              confirmButtonText: "OK",
              confirmButtonColor: palette.primary.main,
              text:"Can not destroy key, You have to revoke key first.",
              didOpen: () => Swal.getConfirmButton()?.focus()
            })
          } else {
            const dataDelete = {
              key_uids: [
                key_uid
              ]
            }
            dispatch(deleteKey(dataDelete));
          }
        }
      });
  }


  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton isLoading={isLoadingKeyList} isLoadingEvent={isLoadingEvent} inputCount={15} titleWidth={'120px'}>
      <Box
      component="form"
      onSubmit={handleSubmit(onRevokeKey)}
      paddingY='20px'>
        <Typography variant="h3" fontFamily="Open Sans" mb={3}>
          Detail & Revocation
        </Typography>
        <InputForm
          name="keyName"
          label="Key Name"
          errors={errors}
          control={control}
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="groupName"
          label="Group Name"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="configName"
          label="Config Name"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="expiryDate"
          label="Expiry Date"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="createdDate"
          label="Created Date"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="status"
          label="Status"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="revokeDate"
          label="Revoke Date"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        {/* <InputForm
          name="revokeReason"
          label="Revoke Reason"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        /> */}
        <TextAreaForm
          name="kcv"
          label="KCV"
          errors={errors}
          control={control}
          required
          maxLength={1000}
          isLoading={false}
          disabled
          defaultValue={""}
        />
        <TextAreaForm
          name="csr"
          label="CSR"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
          defaultValue={""}
        />
        <InputForm
          name="encryptionUsage"
          label="Encryption Usage"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="descryotionUsage"
          label="Descryotion Usage"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <InputForm
          name="signingUsage"
          label="Signing Usage"
          errors={errors}
          control={control}
          required
          maxLength={100}
          isLoading={false}
          disabled
        />
        <SelectFormm
          name="revokeReason"
          label="Revoke Reason Select"
          errors={errors}
          control={control}
          required
          disabled={key_revoke_reason == null ? false : true}
          options={optRevokeSelect}
          isLoading={false}
          defaultValue={""}
        />
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          paddingTop={'20px'}
        >

          <Button
            variant="contained"
            color="gray"
            onClick={() => navigate("/keys-list")}>
            Cancel
          </Button>

          { access.delete &&
            <Button
              variant="contained"
              color="error" 
              onClick={() => onDestroyKey()}>
              Destroy key
            </Button>
          }

          {
            key_revoke_reason == null && access.update
            ? <Button variant="contained" type="submit"> Revoke </Button>
            : null
          }

        </Stack>
      </Box>
    </DetailDataSkeleton>
  );
  
};

export default KeysListDetail;
