import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance } from "../../service/instance";
import { store } from '../index';
import Swal from "sweetalert2";
import { getPageAccessUid } from "../../utils/accessHelper";

interface IKeyList {
  isLoadingKeyList: boolean;
  isLoadingEvent: boolean;
  eventFlag: boolean;
  dataKeyList: any;
  dataKeyDetail: any;
  totalKeys: any;
  errorMsgDetailDelete: any;
  errorMsgDelete: any;
  errorMsgRevoke: any;
  msgSccsFGr: string;
  keyPage: string;
}

const initialState: IKeyList = {
  isLoadingKeyList: false,
  isLoadingEvent: false,
  eventFlag: false,
  dataKeyList: [],
  dataKeyDetail: {},
  totalKeys: 0,
  errorMsgDetailDelete: "",
  errorMsgDelete: "",
  errorMsgRevoke: "",
  msgSccsFGr: "",
  keyPage: ""
};

const KEYS_ACCESS_ID = "/keys-list"

  export const getAllKeys = createAsyncThunk<any, any, { rejectValue: any }>(
    "key/list",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), KEYS_ACCESS_ID)
        const resp: any = await instance.get(`/keys`, {
          params: data,
          headers: {
            'page-access-uid': uid
          }
        });
        const responseData = resp.data.data.data
        // console.log("getAllKeys: ", responseData)
        let totalRecord = resp.data.data.total_records;
        return { responseData, totalRecord };
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const getKeyById = createAsyncThunk<any, any, { rejectValue: any }>(
    "key/detail",
    async (keyId, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), KEYS_ACCESS_ID)
        const resp: any = await instance.get(`/key/${keyId}`, {
          headers: {
            'page-access-uid': uid
          }
        });
        const responseData = resp.data.data;
        return responseData;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const revokeKey = createAsyncThunk<any, any, { rejectValue: any }>(
    "key/revoke",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), KEYS_ACCESS_ID)
        const resp: any = await instance.post(`/revoke_keys`, data, {
          headers: {
            'page-access-uid': uid
          }
        });
        return resp;
      } catch (err) {
        console.log("revokeKey err: ", err)
        throw rejectWithValue(err);
      }
    }
  );

  export const postReKey = createAsyncThunk<any, any, { rejectValue: any }>(
    "key/re-key",
    async (keyId, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), KEYS_ACCESS_ID)
        const resp: any = await instance.post(`/rekey/${keyId}`, {}, {
          headers: {
            'page-access-uid': uid
          }
        });
        return resp;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const deleteKey = createAsyncThunk<any, any, { rejectValue: any }>(
    "key/delete",
    async (data, { rejectWithValue, getState }) => {
      try {
        const uid = await getPageAccessUid(getState(), KEYS_ACCESS_ID)
        const resp: any = await instance.post(`/delete_keys`, data, {
          headers: {
            'page-access-uid': uid
          }
        });
        return resp;
      } catch (err) {
        throw rejectWithValue(err);
      }
    }
  );

  export const keyList = createSlice({
    name: "keyList",
    initialState,
    reducers: {
      setEventFlag: (state) => {
        state.eventFlag = true;
      },
      removeEventFlag: (state) => {
        state.eventFlag = false;
      },
      setPageKey: (state, action) => {
        console.log('from setpagekey store ===> ', action.payload)
        state.keyPage = action.payload
      }
    },
    extraReducers(builder) {
        builder

        // REGION: GET KEY ALL
        .addCase(getAllKeys.pending, (state) => {
            state.isLoadingKeyList = true;
        })
        .addCase(getAllKeys.fulfilled, (state, action) => {
          state.isLoadingKeyList = false;
          const { responseData, totalRecord } = action.payload
          state.dataKeyList = responseData
          state.totalKeys = totalRecord
        })
        .addCase(getAllKeys.rejected, (state) => {
            state.isLoadingKeyList = false;
            state.dataKeyList = []
            state.totalKeys = 0
        })

        // REGION: GET KEY BY ID
        .addCase(getKeyById.pending, (state) => {
          state.isLoadingKeyList = true
        })
        .addCase(getKeyById.fulfilled, (state, action) => {
          state.isLoadingKeyList = false
          state.dataKeyDetail = action.payload
        })
        .addCase(getKeyById.rejected, (state) => {
          state.isLoadingKeyList = false
          state.dataKeyDetail = {}
        })

        // REGION: REVOKE KEY
        .addCase(revokeKey.pending, (state) => {
          state.isLoadingEvent = true;
          state.eventFlag = false
        })
        .addCase(revokeKey.fulfilled, (state) => {
          state.isLoadingEvent = false;
          // Swal.fire("Key Revoked!", "Your key has been revoked", "success")
          Swal.fire({
            icon: "success",
            title: "Key Revoked!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: "Your key has been revoked",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(revokeKey.rejected, (state, action) => {
          state.isLoadingEvent = false;
          var errMsg = ""
          try {
            errMsg = action.payload.response.data.errors[0].detail
          } catch(e) {
            errMsg = "Can not revoke your key, Please try again"
          }
          // Swal.fire("Revoke Key Error!", errMsg, "error")
          Swal.fire({
            icon: "error",
            title: "Revoke Key Error!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: errMsg,
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(removeEventFlag())
          });
        })

        // REGION: RE-KEY
        .addCase(postReKey.pending, (state) => {
          state.isLoadingEvent = true;
          state.eventFlag = false
        })
        .addCase(postReKey.fulfilled, (state,) => {
          state.isLoadingEvent = false;
          // Swal.fire("Re-key Success!", "Your key has been updated", "success")
          Swal.fire({
            icon: "success",
            title: "Re-key Success!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: "Your key has been updated",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(postReKey.rejected, (state, action) => {
          state.isLoadingEvent = false;
          var errMsg = ""
          try {
            errMsg = action.payload.response.data.errors[0].detail
          } catch(e) {
            errMsg = "Can not re-key, Please try again"
          }
          Swal.fire("Re-key Error!", errMsg, "error")
          Swal.fire({
            icon: "error",
            title: "Re-key Error!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: errMsg,
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(removeEventFlag())
          });
        })

        // REGION: DELETE KEY
        .addCase(deleteKey.pending, (state) => {
            state.isLoadingEvent = true;
            state.eventFlag = false
        })
        .addCase(deleteKey.fulfilled, (state) => {
          state.isLoadingEvent = false;
          // Swal.fire("Key Destroyed!", "Your key has been destroyed", "success")
          Swal.fire({
            icon: "success",
            title: "Key Destroyed!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: "Your key has been destroyed",
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(setEventFlag())
          });
        })
        .addCase(deleteKey.rejected, (state, action) => {
          state.isLoadingEvent = false;
          var errMsg = ""
          try {
            errMsg = action.payload.response.data.errors[0].detail
          } catch(e) {
            errMsg = "Can not destroy your key, Please try again"
          }
          // Swal.fire("Destroy Key Error!", errMsg, "error")
          Swal.fire({
            icon: "error",
            title: "Destroy Key Error!",
            showCancelButton: false,
            confirmButtonText: "OK",
            confirmButtonColor: '#98a3a9',
            text: errMsg,
            didOpen: () => Swal.getConfirmButton()?.focus()
          }).then((_) => {
            store.dispatch(removeEventFlag())
          });
        })
    }
  });
  
  export const { setEventFlag, removeEventFlag, setPageKey } = keyList.actions;
  export default keyList.reducer;
  