import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { deleteKey, postReKey,  } from "../../../store/feature/keyList";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { initialAccess } from "../../../store/feature/user";

const KeysListTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLE
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalKeys } = useAppSelector((store: RootState) => store.keyList);
  const [ access, setAccess ] = useState(initialAccess)
  const { accessCrud } = useAppSelector((store: RootState) => store.user);


  // REGION: GET CRUD ACCESS
  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === "/keys-list")
    setAccess(obj != undefined ? obj : initialAccess)
  }, [accessCrud]);

  
  // REGION: CUSTOM RENDER CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATION TO DETAIL
    const onDetailRevocation = (e: any) => {
      e.stopPropagation();
      navigate(`/keys-list/detail/${params.row.keysUid}`);
    };

    // REGION: DISPATCH RE-KEY
    const onReKey = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Re-key Confirmation",
        text: "Are you sure you want to Re-key? New key name will be created and updated in the CA data and the old key will be revoked",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(postReKey(params.row.keysUid));
        }
      });
    };

    // REGION: DISPATCH DELETE KEY
    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Destroy Key Confirmation",
        text: "Are you sure you want to destroy this key?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            key_uids: [
              params.row.keysUid
            ]
          }
          dispatch(deleteKey(data));
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
      { access.read || access.create || access.delete
        ? <Stack spacing={2} direction="row">
            { access.read &&
              <Button variant="contained" onClick={onDetailRevocation}>
                Detail & Revocation
              </Button>
            }
            { access.create &&
              <Button variant="contained" color="gray" onClick={onReKey}>
                Re-Key
              </Button>
            }
            { access.delete &&
              <Button variant="contained" color="error" onClick={onDelete}>
                Destroy key
              </Button>
            }
          </Stack>
        : <Typography variant='body2'> No Action Available </Typography>
      }
      </>
    );

  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "keysName",
      headerName: "Keys Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "centered",
    },
    {
      field: "groupName",
      headerName: "Group Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "configurationName",
      headerName: "Configuration Name",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "keysCreatedAt",
      headerName: "Keys Created At",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "keysStatus",
      headerName: "Keys Status",
      flex: 1,
      minWidth: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.keysStatus !== null ? (
              params.row.keysStatus.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="REVOKED" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1.5,
      minWidth: 450,
      sortable: false,
      align: 'center',
      headerAlign: 'center',
      renderCell: customCellRender,
    },
  ];


  // REGION: INIT ROWS
  const rows = data.map((row: any) => ({
    id: row.kms_keys_id,
    keysUid: row.kms_keys_uid,
    keysName: row.kms_keys_key_name,
    groupName: row.key_group_name,
    keysStatus: row.kms_keys_status,
    configurationName: row.key_configuration_name,
    keysCreatedAt: row.kms_keys_creation_date
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalKeys} />
    </>
  );

};

export default KeysListTable;
