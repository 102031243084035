import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import { getHsm } from "../../store/feature/hsmConfigure";
import HsmConfigurationSearch from "../../components/molecules/hsmConfiguration/HsmConfigurationSearch";
import HsmConfigurationTable from "../../components/molecules/hsmConfiguration/HsmConfigurationTable";
import Toast from "../../components/atoms/toast";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";

const HsmConfiguration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { dataHsmConfig, isLoadingHsmConfig } = useAppSelector((state: RootState)=> state.hsmConfig)

  useEffect(()=> {
    dispatch(getHsm(params))
  },[params])

  return (
    <ListDataSkeleton isLoading={isLoadingHsmConfig} isLoadingEvent={false} titleWidth={'300px'}>
    <Box>
      <Typography variant="h3" fontFamily='Open Sans' sx={{marginY: '20px'}}>
        HSM Configuration
      </Typography>
      {/* <HsmConfigurationSearch
        params={params}
        setParams={setParams}
      /> */}
      <HsmConfigurationTable
        data={dataHsmConfig}
        params={params}
        setParams={setParams}
        defaultValue={defaultValue}
        setDefaultValue={setDefaultValue}
      />
    </Box>
    </ListDataSkeleton>
  );
};

export default HsmConfiguration;