import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../../store";
import {
  deleteFormEntry,
  getBodyTable,
  getHeaderTable,
  removeEntryId,
  removeEventFlag,
  removeUpdateMsg,
} from "../../../store/feature/form";
import { getDefitionForButton } from "../../../store/feature/serviceDefinition";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import AddIcon from "@mui/icons-material/Add";
import { InputForm } from "../../molecules/forms";
import { useForm } from "react-hook-form";
import { getFormKsm, getFormEntryList } from "../../../store/feature/ksm";
import PaginationControl from "../../molecules/pagination/Pagination";
import ListDataSkeleton from "../../template/skeleton/ListDataSkeleton";
import { LoadingButton } from "@mui/lab";
import { initialAccess } from "../../../store/feature/user";
import PaginationDynamic from "../../molecules/pagination/paginationDynamic";

const DynamicTable = () => {
  //#region INIT
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params, setParams] = useState<any>({});
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const formTypeUid = searchParams.get("form-type-uid");
  const {
    formData,
    rowsData,
    formTypeName,
    isLoadingForm,
    isLoadingEvent,
    eventFlag,
    totalRecForm,
  } = useAppSelector((state: RootState) => state.form);
  const { definitionButton } = useAppSelector(
    (state: RootState) => state.serDef
  );
  const [access, setAccess] = useState(initialAccess);
  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const rows: any = [];
  //#endregion

  //#region INIT FORM
  const {
    control,
    watch,
    formState: { errors },
  } = useForm<{}>({
    reValidateMode: "onChange",
    defaultValues: {},
  });
  //#endregion

  //#region INIT CUSTOM CELL
  const customCellRender = (params: any) => {
    const onEdit = (e: any) => {
      e.stopPropagation();
      dispatch(
        getFormEntryList({
          id: params.id,
          page_access_uid: access.page_access_uid,
        })
      );
      const pathnameList = pathname.split("?");
      navigate(
        `${pathnameList[0]}/edit?form-type-uid=${formTypeUid}&params-uid=${params.id}`
      );
    };

    const onDelete = (e: any) => {
      e.stopPropagation();
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(
            deleteFormEntry({
              id: params.id,
              page_access_uid: access.page_access_uid,
            })
          );
        }
      });
    };

    const onDefinition = (e: any) => {
      // e.stopPropagation();
      // dispatch(getFormEntryList(params.id));
      // const pathnameList = pathname.split("?");
      // console.log("semoga saja ===> ", e, params.id, pathnameList);
      // dispatch(createServiceDefinitionById({entryId: params.id, serDevId: e}))
      // navigate(pathname.concat(`/edit/${params.id}`));
      // navigate(
      //   `${pathnameList[0]}/edit?form-type-uid=${formTypeUid}&params-id=${params.id}`
      // );
      // console.log(`${pathnameList[0]}/edit?form-type-uid=${formTypeUid}`)
      // return alert(JSON.stringify(thisRow));
    };

    let justify = "center",
      xs = 3;
    if (definitionButton?.length) {
      justify = "normal";
      xs = 4;
    }
    return (
      <>
        {access.update ||
        access.delete ||
        (access.create && definitionButton) ? (
          <Grid
            container
            spacing={2}
            mt="20px"
            mb="20px"
            justifyContent={justify}
          >
            {access.update && (
              <Grid item xs={xs}>
                <Button
                  variant="contained"
                  onClick={onEdit}
                  sx={{ width: "100%", height: "60px" }}
                >
                  Update
                </Button>
              </Grid>
            )}
            {access.delete && (
              <Grid item xs={xs}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={onDelete}
                  sx={{ width: "100%", height: "60px" }}
                >
                  Delete
                </Button>
              </Grid>
            )}
            {access.create &&
              definitionButton?.map((item: any) => {
                return (
                  <Grid item xs={4}>
                    <Button
                      key={item.service_definition_Id}
                      variant="contained"
                      color="gray"
                      sx={{ width: "100%", height: "60px" }}
                      onClick={() => onDefinition(item)}
                    >
                      {item.service_definition_name}
                    </Button>
                  </Grid>
                );
              })}
          </Grid>
        ) : (
          <Typography variant="body2"> No Action Available </Typography>
        )}
      </>
    );
  };
  //#endregion

  //#region INIT COLUMNS
  const columns = formData.map((row: any) => ({
    field: row.form_param_id.toString(),
    headerName: row.form_param_title,
    flex: 1,
  }));
  columns.push({
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    align: "center",
    minWidth: 260,
    flex: 2,
    sortable: false,
    renderCell: customCellRender,
  });
  //#endregion

  //#region INIT ROWS
  rowsData?.forEach((e: any) => {
    const dataTemp: any = {};
    dataTemp.id = e.form_entry_uid;
    e.form_values?.forEach((i: any) => {
      dataTemp[i.form_parameter_detail.form_parameter_id] =
        i.form_value.toString();
    });
    rows.push(dataTemp);
  });

  //#endregion

  //#region DISPATCH

  useEffect(() => {
    const obj = accessCrud.find((item: any) => item.uid === formTypeUid);
    setAccess(obj != undefined ? obj : initialAccess);
  }, [accessCrud, formTypeUid]);

  useEffect(() => {
    if (
      access.page_access_uid != "" &&
      access.page_access_uid != undefined &&
      access.page_access_uid != null
    ) {
      dispatchData();
    }
  }, [access]);

  useEffect(() => {
    if (eventFlag) {
      dispatchData();
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  const dispatchData = () => {
    dispatch(
      getHeaderTable({
        id: formTypeUid,
        page_access_uid: access.page_access_uid,
      })
    );
    dispatch(
      getBodyTable({
        id: formTypeUid,
        params: { pagesize: 10 },
        page_access_uid: access.page_access_uid,
      })
    );
    dispatch(
      getDefitionForButton({
        id: formTypeUid,
        page_access_uid: access.page_access_uid,
      })
    );
  };

  useEffect(() => {
    if (access.page_access_uid.length !== 0) {
      const filters: any = watch();
      let temp: any = [];
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          const element = filters[key];
          if (element?.length !== 0 && element?.length !== undefined) {
            temp.push(`${key}+${element.trim()}`);
          }
        }
      }
      let param = { filter: temp.join(";;") };
      setParams(param);
      dispatch(
        getBodyTable({
          id: formTypeUid,
          params: param,
          page_access_uid: access.page_access_uid,
        })
      );
    }
  }, [JSON.stringify(watch())]);

  //#endregion

  //#region HANDLE

  const handleInputChange = (e: any) => {
    console.log(" ======> ", e);
  };

  const handleAdd = () => {
    dispatch(removeEntryId());
    dispatch(removeUpdateMsg());
    dispatch(
      getFormKsm({ id: formTypeUid, page_access_uid: access.page_access_uid })
    );
    const pathnameList = pathname.split("?");
    navigate(`${pathnameList[0]}/create?form-type-uid=${formTypeUid}`);
  };

  //#endregion

  //#region RETURN TSX

  return (
    <ListDataSkeleton
      isLoading={isLoadingForm}
      isLoadingEvent={isLoadingEvent}
      colCount={4}
      titleWidth={"300px"}
      isAddButton={true}
    >
      <>
        <Typography variant="h3" sx={{ paddingY: "20px" }}>
          {formTypeName}
        </Typography>
        {access.create && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button variant="contained" onClick={handleAdd}>
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        {access.read && (
          <>
            <Box
              sx={{
                flexgrow: 1,
                mt: "-1rem",
                mb: "0.7rem",
              }}
            >
              <Grid container spacing={2}>
                {formData.map((e: any, i: number) => {
                  return (
                    <Grid item xs={formData.length === 3 ? 4 : 3}>
                      <InputForm
                        name={e.form_param_uid.toString()}
                        errors={errors}
                        control={control}
                        required
                        placeholder={e.form_param_title}
                        maxLength={100}
                        key={e.form_param_id}
                        isLoading={false}
                        onChange={handleInputChange}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <div style={{ height: "650px" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                hideFooter={true}
                disableColumnMenu
                disableColumnSelector
                disableSelectionOnClick
                disableDensitySelector
                getRowHeight={() =>
                  access.update ||
                  access.delete ||
                  (access.create && definitionButton)
                    ? "auto"
                    : 45
                }
              />
              <PaginationDynamic
                params={params}
                setParams={setParams}
                page_access_uid={access.page_access_uid}
              />
            </div>
          </>
        )}
      </>
    </ListDataSkeleton>
  );

  //#endregion
};

export default DynamicTable;
