import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { RootState, useAppSelector } from './store';
import { HsmConfigurationRoutes, HsmConfigurationRoutesEmpty, HsmConfigurationRoutesErr } from './routes/hsmConfiguration';
import { KeysListRoutes, KeysListRoutesEmpty, KeysListRoutesErr } from './routes/keysList';

function App() {

  const { accessCrud } = useAppSelector((store: RootState) => store.user);
  const accessKeysList = accessCrud.find((item: any) => item.uid === "/keys-list")
  const accessHsmConfig = accessCrud.find((item: any) => item.uid === "/hsm-configuration")

  const filteredRoutes = routes[0].children?.filter((item:any) =>
    !KeysListRoutes.includes(item) && !KeysListRoutesErr.includes(item) && !KeysListRoutesEmpty.includes(item) &&
    !HsmConfigurationRoutes.includes(item) && !HsmConfigurationRoutesErr.includes(item) && !HsmConfigurationRoutesEmpty.includes(item)
  )

  if(accessKeysList != undefined) {
    filteredRoutes?.push(...KeysListRoutes)
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...KeysListRoutesErr)
    } else {
      filteredRoutes?.push(...KeysListRoutesEmpty)
    }
  }

  if(accessHsmConfig != undefined) {
    filteredRoutes?.push(...HsmConfigurationRoutes)
  } else {
    if(accessCrud.length != 0) {
      filteredRoutes?.push(...HsmConfigurationRoutesErr)
    } else {
      filteredRoutes?.push(...HsmConfigurationRoutesEmpty)
    }
  }

  routes[0].children = filteredRoutes

  const router = createBrowserRouter(routes);
  return <RouterProvider fallbackElement="..loading" router={router} />;
}

export default App;
