import { ISidebar } from '../interfaces/ISidebar';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FolderIcon from '@mui/icons-material/Folder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const arrSidebarDashboard: Array<ISidebar> = [
  {
    key: "dashboard",
    label: "Dashboard",
    path: "/",
    icon: DashboardTwoToneIcon,
    child: [],
  },
];

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'keys-list',
    label: 'Keys List',
    path: 'keys-list',
    icon: FormatAlignRightIcon,
    child: [],
  },
  { 
    key: 'hsm-configuration',
    label: 'HSM Configuration',
    path: 'hsm-configuration',
    icon: FolderIcon,
    child: [],
  },
  // {
  //   key: 'log-events',
  //   name: 'Log Events',
  //   path: '/log-events',
  //   icon: <BadgeTwoToneIcon />,
  // },
  // {
  //   key: 'admin-mng',
  //   name: 'Admin Mng',
  //   path: '/admin-mng',
  //   icon: <AdminPanelSettingsTwoToneIcon />,
  // },
];
