import React, { useState } from 'react';
import {
  FormControl,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Skeleton,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import FormLabel from '../../../atoms/formLabel/index';
import globalStyles from '../../../../utils/styles';

interface Iprops {
  sxFC?: any;
  sxFL?: any;
  sxFG?: any;
  name: string;
  label?: string;
  errors?: any;
  control?: any;
  options: any;
  disabled?: boolean;
  onChange?: Function;
  defaultValue?: boolean;
  required?: boolean;
  showLabel?: boolean;
  isLoading?: boolean;
}

export default function CheckboxForm(props: Iprops) {
  const {
    sxFC,
    sxFL,
    sxFG,
    name,
    label,
    control,
    errors,
    options,
    disabled,
    onChange,
    defaultValue,
    required = false,
    showLabel = true,
    isLoading = false,
  } = props;
  // console.log('from checkboxform ==> ', options)
  const { palette } = useTheme();
  const [ checked, setChecked ] = useState(false);
  return (
    <FormControl
      sx={{ mb: '0.5rem', ...sxFC }}
      variant="standard"
    >
      {showLabel && (
        <Box sx={{ flexDirection: 'column', display: 'flex', height: '1rem' }}>
          {label !== '' && (
            <FormLabel
              htmlFor={name}
              sx={{
                color: `${palette.neutral.dark} !important`,
                width: '10rem',
                ...sxFL,
              }}
            >
              {label}
              {!required && !disabled && (
                <span
                  style={{
                    marginLeft: '4px',
                    color: palette.neutral.light,
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: '1.5rem',
                    fontStyle: 'italic',
                  }}
                >
                  {/* (optional) */}
                </span>
              )}
            </FormLabel>
          )}
        </Box>
      )}
      <Controller
        // defaultValue={defaultValue}
        control={control}
        name={name}
        render={({ field }) => {
          setChecked(field.value == "" ? false : field.value)
          return (
            <>
              {!isLoading && (
                <FormGroup
                  {...field}
                  sx={{
                    flexDirection: 'row',
                    paddingTop: '0.5rem',
                    marginRight: '0.5rem',
                    ...sxFG,
                  }}
                >
                  <FormControlLabel
                    disabled={disabled}
                    sx={{
                      ...globalStyles(palette),
                      marginLeft: 0,
                      marginRight: 0,
                      '&:hover': {
                        cursor: disabled ? 'no-drop' : 'default',
                      },
                      '&.MuiFormControlLabel-root .Mui-checked+span': {
                        fontSize: '1rem',
                      },
                      '&.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled':
                        {
                          fontSize: '1rem',
                        },
                      '&.MuiFormControlLabel-root .MuiTypography-root': {
                        fontSize: '1rem',
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          '& .MuiCheckbox-root': {
                            padding: 0,
                          },
                          '&.MuiCheckbox-root.MuiButtonBase-root': {
                            padding: 0,
                          },
                          '&.MuiTouchRipple-root': {
                            width: '20 !important',
                          },
                          '&.Mui-disabled svg path': {
                            fill: palette.gray.dark,
                          },
                        }}
                        checked={checked}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          onChange?.(e);
                        }}
                      />
                    }
                    label={options.label}
                  />
                </FormGroup>
              )}
              {isLoading && (
                <Box
                  sx={{
                    marginTop: '16px',
                    display: 'flex',
                    marginRight: '16px',
                  }}
                >
                  <Skeleton
                    style={{
                      height: '20px',
                      width: '20px',
                      borderRadius: '50%',
                      marginRight: '12px',
                    }}
                  />
                  <Skeleton
                    style={{
                      height: '20px',
                      width: '80px',
                      borderRadius: '4px',
                    }}
                  />
                </Box>
              )}
            </>
          )
        }}
      />
      <FormHelperText sx={{ fontSize: '0.75rem', color: palette.error.main }}>
        {errors[name] && errors[name].message}
      </FormHelperText>
    </FormControl>
  );
}
